<template>
  <!-- <div class="module-title"> -->
  <!-- <div class="module-title-desc">
     </div> -->
 <div>
  <pictureModel ref="pictureModel" :pictureShow="pictureShow" v-on="$listeners" @closePicture="closePicture"></pictureModel>
  <div class="boxTitle ">
    <div class="titleText">
      <div class="img"></div>
      <div class="text">{{ title }}</div>
    </div>
    <div class="titleCk" v-if="title=='苗期长势检测'||title=='虫情监测'||type=='xlimg'" @click="OpenPicture">查看</div>
    <div class="title-dw" v-if="type=='dw'">单位（亩）</div>
    <div class="title-dw" v-if="title=='虫情种类'" @click="ckModel">查看</div>
    <div v-else-if="type=='xl'||type=='xlimg'">
      <el-dropdown @command="((el,pr)=>{downClick(el,pr)})">
      <span class="el-dropdown-link">
        {{downxx}}<i class="el-icon-arrow-down el-icon-caret-bottom"></i>
      </span>
      <el-dropdown-menu  slot="dropdown">
        <el-dropdown-item v-for="(item,index) in downList" :key="index" :command="item">{{ item.label }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    </div>
   
  </div>
</div>
 
  <!-- </div> -->
</template>

<script>
import pictureModel from './pictureModel.vue'
export default {
  components:{
    pictureModel:pictureModel
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    type: {
      type: String,
      require: false,
      default: ''
    },
    downList:{
      type:Array,
      default:[]
    },
    
  },
  data(){
    return{
      pictureShow:false,
      downxx:'',
      
      list:[],
      list1:[
        {
          label:'土壤监测点1',
        },
        {
          label:'土壤监测点2',
        },
        {
          label:'土壤监测点3',
        },
        {
          label:'土壤监测点4',
        },
        {
          label:'土壤监测点5',
        }
      ],
      list2:[
        {
          label:'长势检测点1',
        },
        {
          label:'长势检测点2',
        },
        {
          label:'长势检测点3',
        },
        {
          label:'长势检测点4',
        },
        {
          label:'长势检测点5',
        },
      ],
    }
  },
  watch:{
    downList:{
      handler(newData,oldData){
        this.downxx = newData[0].label
      },
      deep:true
    }
  },
  methods:{
    downClick(el,pr){
      this.downxx = el.label
      if(this.title =='长势检测'){
        this.$listeners.getListData(el,2)
      }else if(this.title =='虫情监测'){
        this.$listeners.getListData(el,3)
      }else if(this.title =='气象信息'){
        this.$listeners.getListData(el,4)
      }else if(this.title =='苗期监测'){
        this.$listeners.getListData(el,5)
      }
      else{
        this.$listeners.getListData(el,1)
      }
    

    },
    OpenPicture(){
      this.pictureShow = true
      if(this.title == '苗期监测'){
        this.$refs.pictureModel.getData(this.downList,1)
      }else if(this.title == '虫情监测'){
        this.$refs.pictureModel.getData(this.downList,2)
      }
      
    },
    closePicture(){
      this.pictureShow = false
    },
    //点击查看
    ckModel(){
     this.$listeners.btn(true,{
      title:this.title,
      type:'chart'
     })
    }
  },
  mounted(){
    if(this.title == '墒情信息'){
      this.list = this.list1
    }else{
      this.list = this.list2
    }
  }
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}
.boxTitle {
  position: absolute;
  width: 95%;
  height: companyH(35vh);
  background: linear-gradient(90deg, #032b29 0%, rgba(9, 41, 38, 0) 100%);
  display: flex;
  justify-content: space-between;
  padding: companyH(6vh) companyW(10vw) companyH(0) companyW(10vw);
  .titleText {
    display: flex;
  
    .img {
      background-image: url("../../../assets/number-page/module_title.png");
      width: companyH(25vh);
      height: companyH(25vh);
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .text {
      font-size: companyH(20vh);
      font-weight: bold;
      color: #ffffff;
      height: companyH(25vh);
      line-height: companyH(25vh);
      padding-left: companyW(10vw);
    }
  }
  .titleCk{
    font-size: companyH(20vh);
      font-weight: bold;
      color: #ffffff;
      height: companyH(25vh);
      line-height: companyH(25vh);
  }
  .title-dw {
    font-size: companyH(20vh);
      font-weight: bold;
      color: #ffffff;
      height: companyH(25vh);
      line-height: companyH(25vh);
      padding-right: companyW(10vw);
  }
}
.module-title {
  position: absolute;
  top: companyH(-44vh);
  left: companyW(-19vw);
  width: companyW(450vw);
  height: companyH(109vh);

  background-size: cover;
  .module-title-desc {
    img {
      height: companyH(18vh);
    }
    position: absolute;
    left: companyW(53vw);
    bottom: companyH(45vh);
    width: companyW(126vw);
    height: companyH(18vh);
    font-size: companyH(18vh);
    font-family: Source Han Sans CN;
    font-weight: 700;
    font-style: italic;
    color: #ffffff;
    line-height: companyH(15vh);
    text-shadow: 0px 0px 9px rgba(32, 22, 0, 0.45);
  }
 
}
.el-dropdown-link {
  font-size: companyH(18vh);
    cursor: pointer;
    color: #fff;
    height: companyH(25vh);
      line-height: companyH(25vh);
    font-weight: bold;
  }
  .el-icon-arrow-down {
    font-weight: bold;
    color: rgb(2, 163,122);
    font-size: 12px;
  }
::v-deep .popper-select {
  position: absolute !important;
  top: 35px !important;
  left: 0px !important;
}
</style>
