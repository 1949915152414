<template>
    <div>
        <el-dialog :visible.sync="pictureShow" :show-close="false" @close="closeMask">
            <dv-border-box-1 :color="['#BBE8DC', '#015C44']">
                <div class="close" @click="closeMask"></div>
                <div class="pictureBox">
                    <div class="pictureTitle">历史记录</div>
                    <div class="tool">
                        <div class="select">
                            <el-select v-model="downxx" placeholder="请选择" @change = "selectChange">
                                <el-option v-for="item in selectList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="block">
                            <el-date-picker unlink-panels format="yyyy-MM-dd" @change = "selectChange" v-model="timeList" type="daterange"
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                        <div class="cx">
                            <el-button @click="search">查询</el-button>
                        </div>
                    </div>
                    <div class="picture">
                        <div class="picture-item" v-for="(item, index) in imgList" :key="'s' + index">
                            <el-image style="width: 100%; height: 100%" :src="item" :preview-src-list="imgList">
                            </el-image>
                        </div>
                    </div>
                    <div class="sz-pagination">
                        <el-pagination :current-page.sync="currentPage" @current-change="currentChange"
                            layout=" slot,prev, pager, next" :total="tableTotal">
                            <span class="fy-slot">共{{ tableTotal }}条记录</span>
                        </el-pagination>
                    </div>
                </div>

            </dv-border-box-1>
        </el-dialog>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import { getSeedlingsHistory, getPestMonitoringHistory } from '@/api/numberApi/index.js'


export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
          
            currentPage: 1,
            tableTotal: 0,
            type: 0,
            //监测点选项
            downxx: '',
            selectList: [
            ],
            //时间选项
            timeList: [],
            list2: [
                {
                    label: '长势检测点1',
                },
                {
                    label: '长势检测点2',
                },
                {
                    label: '长势检测点3',
                },
                {
                    label: '长势检测点4',
                },
                {
                    label: '长势检测点5',
                },
            ],
            imgList: [
            ],
        };
    },
    props: {
        pictureShow: {
            type: Boolean,
            default: false
        },
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        selectChange(){
            this.currentPage = 1
        },
        //页面改变
        currentChange(e) {
           
            this.search()
            setTimeout(() => {
              
            }, 1000);
        },
        formatDateTime(date) {
            let pad = function (num) {
                return num.toString().padStart(2, '0');
            }
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            return `${year}-${pad(month)}-${pad(day)}`;
        },

        closeMask() {
            this.$listeners.closePicture()
        },
        downClick(el, pr) {
            this.downxx = el
        },
        //获取list数据
        async getData(list, type) {
            this.type = type
            this.selectList = list
            if (this.selectList && this.selectList.length) {
                this.downxx = this.selectList[0].value
                await this.search()
            }
        },
        async search() {
            let obj = {
                pageNum:1,
                pageSize:10
            }
            obj.pageNum = this.currentPage
            if (this.timeList && this.timeList.length) {
                this.$set(obj, 'begin',
                    this.formatDateTime(this.timeList[0])
                )
                this.$set(obj, 'end',
                    this.formatDateTime(this.timeList[1])
                )
            }
            if (this.type == 1) {
                if (this.downxx) {
                    this.$set(obj, 'seedlingName', this.downxx)
                }
                //监测点名称获取苗情信息
                await getSeedlingsHistory(obj).then(res => {
                    if (res.code == 1) {
                        ////console.log(res, 'res');
                        if (res.data.length == 0) {
                            this.$message.error('所选节点尚无历史记录')
                        } else {
                            this.imgList = res.data.records.map(item => {
                                return item.seedlingsPicture
                            })
                        }

                        // this.mqData = res.data
                        // this.mqData = this.splitArray(res.data, 2)
                    }
                })
            } else {
                if (this.downxx) {
                    this.$set(obj, 'deviceId', this.downxx)
                }
                await getPestMonitoringHistory(obj).then(res => {
                    if (res.code == 1) {
                        //console.log(res, 'res');
                        if (res.data.records.length == 0) {
                            this.$message.error('所选节点尚无历史记录')
                        } else {
                            this.tableTotal = res.data.total
                            this.imgList = res.data.records.map(item => {
                                return item.monitoringPicture
                            })
                        }
                        // this.mqData = res.data
                        // this.mqData = this.splitArray(res.data, 2)
                    }
                })
            }


        },

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@function companyW($num) {
    @return ($num * 100)/1920;
}

@function companyH($num) {
    @return ($num * 100)/1080;
}

/* 分页器样式 */
.sz-pagination {
    margin-top: companyH(10vh);
    text-align: center;
    width: 100%;

    .fy-slot {
        margin-right: companyW(10vw);
        color: rgb(2, 167, 125);
    }

    ::v-deep {
        .btn-prev {
            background: #000;
        }

        .el-pager {
            background: #000;
            color: rgb(1, 193, 144);

            .number {
                background: #000;
                border: 1px solid rgb(1, 193, 144);
            }

            .number:hover {
                color: #fff
            }
        }

        .btn-next {
            background-color: black;

        }

        .el-icon-arrow-right {
            background-color: black;
        }

        .el-icon-arrow-left:before {
            color: rgb(2, 167, 125);
            border: 1px solid rgb(1, 193, 144);
        }

        .el-icon-arrow-right:before {
            color: rgb(2, 167, 125);
            border: 1px solid rgb(1, 193, 144);
        }

        .active {
            color: #fff;
            font-weight: 400;
        }

        .el-pager li.btn-quicknext,
        .el-pager li.btn-quickprev {
            background-color: #000;
            color: rgb(2, 167, 125);
            border: 1px solid rgb(1, 193, 144)
        }
    }
}

::v-deep .el-dialog {
    width: companyW(1499vw);
    background: #000000;
}

::v-deep .el-dialog__body {
    padding: 0;
}

::v-deep .el-dialog__header {
    padding: 0;
}

.pictureBox {
    width: calc(100% - companyW(260vw));
    height: companyH(600vh);
    padding: companyH(42vh) companyW(130vw) 0 companyW(130vw);
}

.pictureTitle {
    color: #fff;
    font-size: companyW(22vw);
    padding-top: companyH(42vh);
    text-align: center;
    margin-bottom: companyH(48vh);
}

.tool {
    height: companyH(30vh);
    display: flex;
    margin-bottom: companyH(20vh);
}

.select,
.block,
.cx {
    margin-right: companyW(20vw);
}

::v-deep {
    .el-input__inner {
        height: companyH(30vh) !important;
        background-color: #061414 !important;
        color: #fff !important;
        border: 1px solid #07B185;
    }

    .select {
        .el-input__icon {
            height: companyH(40vh); //设置高度，icon元素恢复原来的高度，这时arror才能垂直居中
            position: relative;
            top: companyH(-5vh);
            // 元素整体上移，让箭头也居中
            color: #fff;
        }
    }

    .block {
        .el-input__icon {
            height: companyH(30vh); //设置高度，icon元素恢复原来的高度，这时arror才能垂直居中
            position: relative;
            top: companyH(-3vh);
            // 元素整体上移，让箭头也居中
        }

        .el-range-separator {
            line-height: companyH(20vh);
        }

        .el-range-input {
            background-color: #061414;
            color: #fff;
        }

        .el-range-separator {
            color: #fff;
        }
    }

    .cx {
        .el-button {
            height: companyH(30vh);
            line-height: companyH(4vh);
            background-color: #061414;
            color: #fff;
            border: 1px solid #07B185;
        }
    }
}

.picture {
    height: companyH(350vh);
    background-color: #01130E;
    display: flex;
    overflow-y: auto;
    flex-wrap: wrap;
    justify-content: left;
    align-content: flex-start;
    padding: 0 companyW(20vw);

    .picture-item {
        height: companyH(138vh);
        width: calc((100% - companyW(80vw)) / 5);
        margin: companyH(20vh) companyW(20vw) 0 0;

        &:nth-child(5n) {
            // 去除第3n个的margin-right
            margin-right: 0;
        }
    }
}

.close {
    width: companyW(40vw);
    height: companyW(40vw);
    background: url('../../../assets/close.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: companyH(25vh);
    right: companyW(35vw);
}</style>